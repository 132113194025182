import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";


export default class Insight {
    heading = "";
    constructor(date, seo, img, title, sub, preview, jsx) {
        this.date = date;
        this.seo = seo;
        this.title = title;
        this.img = img;
        this.sub = sub;
        this.preview = preview;
        this.jsx = jsx;
    }

    get InsightJSX() {
        // pageTitle, metaDescription, keyWords 
        return (
            <div className="insight-page-container" onLoad={() => { window.scrollTo(0, 0); }}>
                <Helmet>
                    <meta name="description" content={this.seo.metaDescription} />
                    <meta name="keywords" content={this.seo.keyWords} />
                </Helmet>
                {this.jsx}
            </div>
        );
    }


    get previewJSX() {
        return (
            // <div >
            <NavLink to={`/insights/${this.title.toLowerCase().split(" ").join("-").replace("!", "")}`} target="_blank" className="insight-container">
                <img src={this.img.src} alt={this.img.alt} />
                <div>
                    {this.heading === "h2"
                        ?
                        <h2>{this.title}</h2>
                        :
                        this.heading === "h3" && <h3>{this.title}</h3>
                    }
                    <p className="sub">{this.sub}</p>
                    <p style={{ marginBottom: "30px", opacity: "0.4" }}>{this.date}</p>
                    <p className="preview">{this.preview}</p>
                </div>
            </NavLink>
            // {/* </div> */}
        );
    }

}

